import { NavLink, useLocation } from "react-router-dom";
import { useContext } from "react";
import { LanguageContext } from "../context/language";
import LanguageSelection from "./LanguageSelection";
import Theme from "./Theme";
import navData from "../data/navData.json";

function NavButtons({ toggleMenu, dark, setDark }) {
    const { language } = useContext(LanguageContext);
    const location = useLocation();
    const pages = navData.pages;

    const buttonClass = "mr-[20px] p-[10px] text-center hover:text-white dark:hover:text-blue-400";
    const buttonActive = buttonClass + " font-extrabold";

    // Helper function to determine if the NavLink is active
    const isActiveLink = (link) => {
        const path = location.pathname;
        const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
        const normalizedLink = link.endsWith('/') ? link.slice(0, -1) : link;
        return normalizedPath === `/${language.lang}${normalizedLink}`;
    };

    const buttons = pages.map((page, key) => {
        return (
            <NavLink
                key={key}
                to={`/${language.lang}${page.link}`}
                className={() => isActiveLink(page.link) ? buttonActive : buttonClass}
                onClick={toggleMenu}
            >
                {page[language.lang]}
            </NavLink>
        );
    });

    return (
        <div className="flex flex-col md:inline h-[100vh]">
            {buttons}
            <div className="md:hidden flex justify-center">
                <NavLink
                    className={(navData) => (navData.isActive ? buttonActive : buttonClass)}
                    to="/impressum"
                    onClick={toggleMenu}
                >
                    Impressum
                </NavLink>
            </div>
            <div className="flex justify-center md:inline-flex">
                <LanguageSelection toggleMenu={toggleMenu} />
                <Theme toggleMenu={toggleMenu} dark={dark} setDark={setDark} />
            </div>
        </div>
    );
}

export default NavButtons;
