import impressumData from '../data/impressumData.json';

export default function Impressum({ language }) {

    const data = impressumData[language];

    return (
        <div className='impressum-content h-full p-5 scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto'>
            <h1>Impressum</h1>
            <h2 className="text-blue-900 dark:text-blue-400 text-left pb-1">{data.according}</h2>
            <p>Pio Saija</p>  
            <p>Helsingforser Straße 23</p>
            <p>10245 Berlin</p>

            <h2 className="text-blue-900 dark:text-blue-400 text-left p-0 mt-3">{data.represented}</h2>
            <p>Pio Saija</p>
            <h2 className="text-blue-900 dark:text-blue-400 text-left p-0 mt-3">{data.contact}</h2>
            <p>info@piosaija.eu</p>
            <h2 className="text-blue-900 dark:text-blue-400 text-left p-0 mt-5">{data.body.title}</h2>
            <ul className="pl-5">
                {
                    data.body.content.map((obj, index) => (
                        <li key={index} className="mt-1">
                            <h3 className="text-blue-900 dark:text-blue-400 text-left p-0">{Object.keys(obj)}: </h3>
                            <p className='text-justify'>{obj[Object.keys(obj)]}</p>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}
