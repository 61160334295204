import TechList from "../components/TechList";

const Tech = ({ languageData }) => {
    return (
        <div className="h-full text-justify hyphens-auto scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto">
            <h1 className="bg-blue-800/70 p-3 mb-5 shadow-lg border-b">
                {languageData.tech.title}
            </h1>
            <div className="p-5 md:p-10">
                <p className="text-blue-900 dark:text-white mb-5">{languageData.tech.content}</p>
                {languageData.tech.tech_content.map((obj, index) =>
                    Array.isArray(obj[Object.keys(obj)]) ? (
                        <TechList key={index} data={obj} />
                    ) : (
                        <div key={index} className="mt-4">
                            <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">
                                {Object.keys(obj)}
                            </h2>
                            <p>{obj[Object.keys(obj)]}</p>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default Tech;
