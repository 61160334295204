function NotFound() {
    return (
        <div className="flex flex-col items-center justify-center w-full h-full bg-blue-700 text-white">
            <h1 className="text-4xl font-bold mb-4">404</h1>
            <p className="text-lg">Page Not Found</p>
        </div>
    );
}

export default NotFound;
