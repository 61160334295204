import { useState } from "react";
import NavButtons from "./NavButtons";

function Nav({ dark, setDark }) {

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    return (
        <nav className="fixed w-[100vw] bg-white/30 backdrop-blur-md text-blue-800 blur-background dark:bg-black/30  dark:text-white shadow-[0_10px_15px_-3px_rgba(0,0,0,0.3)] z-40">
            <div className="container mx-auto flex items-center justify-between h-[7vh] px-5">
                <div className="flex-shrink-0 font-bold tracking-wider">Pio Saija</div>
                <div className="hidden fixed md:block md:relative">
                    <NavButtons dark={dark} setDark={setDark} />
                </div>
                <button
                    type="button"
                    className="md:hidden flex items-center justify-center p-2 rounded-md focus:outline-none transition duration-150 ease-in-out dark:text-white"
                    onClick={toggleMenu}
                >
                    <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        ></path>
                    </svg>
                </button>
            </div>
            <div className="md:hidden">{isOpen && <NavButtons toggleMenu={toggleMenu} dark={dark} setDark={setDark} />}</div>
        </nav>
    );
}

export default Nav;
