import { Routes, Route, useLocation } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./context/language";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import About from "./pages/About";
import Tech from "./pages/Tech";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import Impressum from "./pages/Impressum";
import NotFound from "./pages/NotFound";
import SquaresContainer from "./components/SquaresContainer";
import Footer from "./components/Footer";
import ImageSlider from "./components/ImageSlider";
import Spinner from "./components/Spinner";

function App() {

  const [dark, setDark] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : false;
  });
  // Apply the theme to the body class and save it to localStorage
  useEffect(() => {
    if (dark) {
        document.body.classList.add('dark');
    } else {
        document.body.classList.remove('dark');
    }
    localStorage.setItem('theme', JSON.stringify(dark));
  }, [dark]);

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [images, setImages] = useState();
  const [loading, setLoading] = useState(true);
  const [sliderLoading, setSliderLoading] = useState(false);

  const { language } = useContext(LanguageContext);
  const [languageData, setLanguageData] = useState(null);
  const location = useLocation();
  const isHome = location.pathname === `/${language.lang}` || location.pathname === `/${language.lang}/`;

  const handleOpenSlider = () => {
    setIsSliderOpen(!isSliderOpen);
    if (!isSliderOpen) {
      setSliderLoading(true);
      // Simulate image loading
      setTimeout(() => {
        setSliderLoading(false);
      }, 2000); // 2 seconds delay for image loading
    }
  };

  useEffect(() => {
    // Close the gallery when the route changes
    setIsSliderOpen(false);
  }, [location]);

  useEffect(() => {
    const fetchLanguageData = async () => {
      setLoading(true);
      try {
        const data = require('./data/languageData.json'); // Load your JSON data

        if (data) {
          setLanguageData(data[language.lang]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching language data:", error);
        setLoading(false);
      }
    };

    if (language.lang) {
      fetchLanguageData();
    } else {
      setLoading(false);
    }
  }, [language.lang]);

  if (loading) {
    return <Spinner />; // Show spinner during loading
  }

  if (!languageData) {
    return <Spinner />; // Show spinner if language data is not yet loaded
  }

  return (
    <div className="relative w-[100vw] h-[100vh] overflow-hidden">
      <SquaresContainer id="squaresContainer" />
      <div className="absolute top-0 left-0 w-full h-full md:pattern-bg dark:pattern-bg-dark z-0"></div>
      {sliderLoading && <Spinner />}
      {isSliderOpen && !sliderLoading && <ImageSlider images={images} onClose={handleOpenSlider} />}
      {
        <div className="w-[100vw] h-[100vh] relative overflow-hidden">
          {isHome ? null : <Nav dark={dark} setDark={setDark} />}
          <div className={`container m-auto ${isHome ? "pt-[1vh]" : "pt-[9vh] px-[2vh] pb-[2vh]"}`}>
            <main className={`${isHome ? "h-[98vh] lg:h-[89vh]" : "h-[89vh] md:h-[82vh] backdrop-blur-md dark:bg-blue-950/30 bg-white/30 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.3)]"} w-full text-lg md:drop-shadow-lg md:rounded-xl z-10 overflow-hidden`}>
              <Routes>
                <Route path=":lang">
                  <Route index element={<Home languageData={languageData} />} />
                  <Route path="about" element={<About languageData={languageData} />} />
                  <Route path="tech" element={<Tech languageData={languageData} />} />
                  <Route path="projects" element={<Projects languageData={languageData} />} />
                  <Route path="project/:projectName" element={<Project languageData={languageData} handleOpenSlider={handleOpenSlider} setImages={setImages} />} />
                  <Route path="contact" element={<Contact languageData={languageData} />} />
                  <Route path="impressum" element={<Impressum language={language.lang} />} />
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            {
              isHome ?
                null :
                <footer className="hidden md:relative md:block w-[100%] h-[7vh] p-2 dark:text-white z-10">
                  <Footer />
                </footer>
            }
          </div>
        </div>
      }
    </div>
  );
}

export default App;
