import { Link } from 'react-router-dom';

export default function Footer() {

    const year = new Date().getFullYear();

    return (
        <div className='flex justify-between'>
            <h4 id='copyright'>Copyright © {year} Pio Saija</h4>
            <Link className='hover:text-blue-800 dark:hover:text-blue-400' to='impressum'>Impressum</Link>
        </div>
    )
}