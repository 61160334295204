import ProjectCard from "../components/ProjectCard";

const Projects = ({ languageData }) => {
    return (
        <div className="h-full scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto">
            <h1 className="bg-blue-800/70 p-3 mb-5 shadow-lg border-b">{languageData.projects.title}</h1>
            <p className="text-center p-5 text-blue-900 dark:text-white">{languageData.projects.content}</p>
            <div className="p-5">
                {
                    languageData.projects.projects_list.map((project, index) => (
                        <ProjectCard key={index} project={project} />
                    ))
                }
            </div>
        </div>
    );
};

export default Projects;
