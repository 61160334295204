const Spinner = () => {
    return (
        <div className="flex flex-col items-center justify-center h-[100vh] z-50 bg-gray-800/50">
            <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-500"></div>
            <span className="pt-5 text-white">Loading...</span>
        </div>
    );
};

export default Spinner;
