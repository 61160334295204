import { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import projectsData from "../data/projectsData.json";
import Spinner from "../components/Spinner";

function Project({ languageData, handleOpenSlider, setImages }) {
    const { projectName } = useParams();

    const project = languageData.projects.projects_list.filter(
        (project) => project.projectName === projectName
    )[0];

    const tech = projectsData[projectName].tech;
    const techLength = tech.length;
    const links = projectsData[projectName].links;
    const images = projectsData[projectName].images;
    const techUsed = languageData.projects.tech_used;
    let gridCols = "grid-cols-1";
    let mdGridCols = "md:grid-cols-1";

    if (techLength > 1 && techLength < 4) {
        gridCols = `grid-cols-${techLength}`;
        mdGridCols = `md:grid-cols-${techLength}`;
    } else if (techLength > 3 && techLength < 6) {
        gridCols = `grid-cols-3`;
        mdGridCols = `md:grid-cols-${techLength}`;
    } else if (techLength >= 6) {
        gridCols = `grid-cols-3`;
        mdGridCols = `md:grid-cols-6`;
    }

    useEffect(() => {
        setImages(images);
    }, [images, setImages]);

    if (!project) {
        return <Spinner />;
    }

    return (
        <div className="h-full scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto">
            <h1 className="bg-blue-800/70 p-3 mb-5 shadow-lg border-b">{project.projectName}</h1>
            <div className="p-5 md:p-10">
                <p className="text-blue-900 dark:text-white text-justify hyphens-auto pb-4">
                    {project.projectOverview}
                </p>
                <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">{techUsed}</h2>
                <div className="flex justify-center">
                    <div
                        className={`md:max-w-[90%] grid ${gridCols} ${mdGridCols} justify-center gap-4 pb-4`}
                    >
                        {tech.map((item, i) => {
                            return (
                                <div
                                    key={i}
                                    className="max-w-24 grid grid-flow-row border p-1"
                                >
                                    <img
                                        src={`/images/icons/${item}.png`}
                                        alt={item}
                                    />
                                    <p className="text-center text-sm pt-1">{item}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">Key Features</h2>
                <ul>
                    {project.keyFeatures.map((feature, index) => (
                        <li key={index}>
                            <h3 className="text-left text-blue-900 dark:text-blue-400 pb-1 mt-4">
                                {feature.title}
                            </h3>
                            <p className="text-justify">{feature.description}</p>
                            {feature.subFeatures && (
                                <ul className="list-disc p-5">
                                    {feature.subFeatures.map((subFeature, subIndex) => (
                                        <li key={subIndex}>
                                            <h4 className="text-blue-900 dark:text-blue-400">
                                                {subFeature.title}
                                            </h4>
                                            <p className="text-justify">
                                                {subFeature.description}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>

                <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">
                    Design and User Experience
                </h2>
                <p className="text-justify">
                    {project.designAndUserExperience.description}
                </p>

                <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">Project Status</h2>
                <p className="text-justify">{project.projectStatus}</p>
                {links && (
                    <div className="grid">
                        <h3 className="bg-blue-700/50 my-4 p-1 shadow-md">Links</h3>
                        {links.map((link, i) => (
                            <Link
                                key={i}
                                to={link.value}
                                target="blank"
                                className="text-center p-2"
                            >
                                {link.title}
                            </Link>
                        ))}
                    </div>
                )}
                <div className="w-full flex justify-center my-8">
                    <button
                        onClick={handleOpenSlider}
                        className="bg-blue-700/30 hover:bg-blue-700/80 p-2 border border-white shadow-md"
                    >
                        Screenshots
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Project;
