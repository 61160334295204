import { useContext } from "react";
import { LanguageContext } from "../context/language";

function LanguagePreSelection() {
    const { setLanguage } = useContext(LanguageContext);

    const handleLanguageChange = (lang) => {
        setLanguage({ lang });
        localStorage.setItem("preferredLanguage", lang);
        document.cookie = `preferredLanguage=${lang}; path=/;`;

        // Get the current path and replace the language segment
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split("/");
        pathParts[1] = lang; // Update language segment

        // Create the new path with the updated language
        const newPath = pathParts.join("/");

        // Redirect to the new path
        window.location.href = newPath;
    };

    return (
        <div className="w-[100vw] h-[100vh] bg-blue-700 flex justify-center overflow-hidden">
            <div className="grid grid-cols-1 gap-4 place-content-center">
                <button
                    className="flex justify-center px-20 py-10 bg-white/30 backdrop-blur-md text-white font-bold border-solid border-2 rounded-md border-white/50 cursor-pointer"
                    onClick={() => handleLanguageChange("en")}
                >
                    English
                </button>
                <button
                    className="flex justify-center px-20 py-10 bg-white/30 backdrop-blur-md text-white font-bold border-solid border-2 rounded-md border-white/50 cursor-pointer"
                    onClick={() => handleLanguageChange("de")}
                >
                    Deutsch
                </button>
                <button
                    className="flex justify-center px-20 py-10 bg-white/30 backdrop-blur-md text-white font-bold border-solid border-2 rounded-md border-white/50 cursor-pointer"
                    onClick={() => handleLanguageChange("it")}
                >
                    Italiano
                </button>
            </div>
        </div>
    );
}

export default LanguagePreSelection;
