import { useState } from "react";

const getImageName = (path) => {
    const fileNameWithExt = path.split('/').pop();
    const fileName = fileNameWithExt.split('.')[0];
    return fileName;
};

const ImageSlider = ({ images, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    const [touchPosition, setTouchPosition] = useState(null);

    const changeSlide = (newIndex) => {
        setIsFading(true);
        setTimeout(() => {
            setCurrentIndex(newIndex);
            setIsFading(false);
        }, 500); // The duration of the fade transition
    };

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
        changeSlide(newIndex);
    };

    const goToNext = () => {
        const isLastSlide = currentIndex === images.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        changeSlide(newIndex);
    };

    //if mobile
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            goToNext();
        }

        if (diff < -5) {
            goToPrevious();
        }

        setTouchPosition(null);
    };

    //dots index
    const handleDotClick = (index) => {
        setCurrentIndex(index);
    };

    const imageName = getImageName(images[currentIndex]);

    return (
        <div
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-85 z-50"
        >
            <div className="relative w-full h-full grid grid-cols-7 grid-rows-7 items-center">
                <div className="row-start-1 col-start-2 col-span-5">
                    <h3>{imageName}</h3>
                </div>
                <img
                    src={images[currentIndex]}
                    alt={`Slide ${imageName}`}
                    className={`object-contain h-full w-full portrait:col-start-1 col-start-2 row-start-2 row-span-5 portrait:col-span-7 col-span-5 transition-opacity duration-500 ${
                        isFading ? "opacity-0" : "opacity-100"
                    }`}
                />

                {!isTouchDevice && (
                    <>
                        <button
                            onClick={goToPrevious}
                            className="w-12 col-start-1 row-start-4 ml-5 justify-self-start text-white text-2xl font-bold bg-blue-500 bg-opacity-50 p-2 rounded-full"
                        >
                            &#9664;
                        </button>
                        <button
                            onClick={goToNext}
                            className="w-12 col-start-7 row-start-4 mr-5 justify-self-end text-white text-2xl font-bold bg-blue-500 bg-opacity-50 p-2 rounded-full"
                        >
                            &#9654;
                        </button>
                    </>
                )}

                <button
                    onClick={onClose}
                    className="absolute w-7 h-7 top-5 right-5 flex justify-center items-center text-white text-2xl font-bold bg-red-600/85 rounded-full"
                >
                    &#10005;
                </button>
                <div className="h-[5vh] col-start-2 row-start-7 col-span-5 flex justify-center mt-4 space-x-2">
                {images.map((_, i) => (
                    <div
                        key={i}
                        onClick={() => handleDotClick(i)}
                        className={`h-3 w-3 rounded-full cursor-pointer ${
                            currentIndex === i ? "bg-blue-500" : "bg-gray-300"
                        }`}
                    />
                ))}
                </div>
            </div>
        </div>
    );
};

export default ImageSlider;
