import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './context/language';
import About from './pages/About';
import Tech from "./pages/Tech";
import Projects from "./pages/Projects";
import Project from './pages/Project';
import Contact from "./pages/Contact";
import Impressum from './pages/Impressum';
import NotFound from "./pages/NotFound";
import LanguagePreSelection from "./components/LanguagePreSelection";
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const LanguageWrapper = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const lang = location.pathname.split('/')[1]; // Extract language from the URL

  useEffect(() => {
    const preferredLanguage = localStorage.getItem('preferredLanguage');

    if (['en', 'de', 'it'].includes(lang)) {
      // If the URL has a valid language, update localStorage
      localStorage.setItem('preferredLanguage', lang);
      document.cookie = `preferredLanguage=${lang}; path=/;`;
    } else {
      // If no valid language in URL, use the language from localStorage or show LanguagePreSelection
      if (preferredLanguage) {
        navigate(`/${preferredLanguage}${location.pathname}`, { replace: true });
      } else {
        navigate('/select-language', { replace: true });
      }
    }
  }, [lang, location.pathname, navigate]);

  return children;
};

const router = createBrowserRouter([
  {
    path: '/select-language',
    element: <LanguagePreSelection />,
  },
  {
    path: "/",
    element: <LanguageWrapper><App /></LanguageWrapper>,
    children: [
      {
        path: "about",
        element: <About />,
      },
      {
        path: "tech",
        element: <Tech />,
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "project/:projectName",
        element: <Project />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "impressum",
        element: <Impressum />,
      },
      {
        path: "*",
        element: <NotFound />, // Handle 404 in the main app route
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <LanguageProvider>
      <RouterProvider router={router} />
    </LanguageProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
