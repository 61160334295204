import { useEffect, useState } from "react";
import ExpandingSquare from "./ExpandingSquare";

function ExpandingButton({ page, link, imageLinks, description, animation, delay }) {
    const [start, setStart] = useState(false);

    useEffect(() => {
        const startTimer = setTimeout(() => {
            setStart(true);
        }, delay * 3000);

        return () => clearTimeout(startTimer);
    }, [delay]);

    return (
        start && (
            <ExpandingSquare
                page={page}
                link={link}
                imageLinks={imageLinks}
                description={description}
                animation={animation}
            />
        )
    );
}

export default ExpandingButton;
