import { useContext, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCaptcha from "../components/ReCaptcha";
import { LanguageContext } from "../context/language";

export default function Contact({ languageData }) {
    const form = useRef();
    const { language } = useContext(LanguageContext);
    const successMessage = languageData.contact.response.success;
    const errorMessage = languageData.contact.response.error;

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_8298tmn",
                "template_qx8lnck",
                form.current,
                "MtRz-U23T60Guqdmp"
            )
            .then(
                (response) => {
                    console.log(response.text);
                    alert(successMessage);
                },
                (error) => {
                    console.log(error.text);
                    alert(errorMessage);
                }
            );
        e.target.reset();
    };

    const title = languageData.contact.title;
    const content = languageData.contact.content;
    const name = languageData.contact.form.name;
    const email = languageData.contact.form.email;
    const message = languageData.contact.form.message;
    const sendButton = languageData.contact.form.button;

    return (
        <div
            id="contact"
            className="h-full scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto"
        >
            <h1 className="bg-blue-800/70 p-3 mb-4 shadow-lg border-b">{title}</h1>
            <p className="text-blue-900 dark:text-white whitespace-pre-line hyphens-auto text-center px-5 mb-3">
                {content}
            </p>
            <div className="flex justify-center p-5">
                <form
                    ref={form}
                    onSubmit={sendEmail}
                    className="w-full md:w-[40%] flex flex-col"
                >
                    <label htmlFor="contact-name">{name}</label>
                    <input
                        className="text-blue-900 mb-4 px-2 shadow-inner shadow-slate-400 border"
                        type="text"
                        id="contact-name"
                        name="user_name"
                        tabindex="0"
                        required
                    />
                    <label htmlFor="contact-email-address">{email}</label>
                    <input
                        className="text-sky-900 mb-4 px-2 shadow-inner shadow-slate-400 border"
                        type="text"
                        id="contact-email-address"
                        name="user_email"
                        tabindex="1"
                        required
                    />
                    <label htmlFor="contact-message">{message}</label>
                    <textarea
                        className="text-sky-900 mb-6 px-2 h-40 shadow-inner shadow-slate-400 border"
                        id="contact-message"
                        name="message"
                        tabindex="2"
                        required
                    />
                    <div id="check-send" className="grid justify-items-end">
                        <ReCaptcha lang={language.lang} tabindex="3" />
                        <button
                            id="send-button"
                            className="bg-blue-700/30 hover:bg-blue-700/80 border px-2 mt-3 shadow-lg text-xl"
                            tabindex="4"
                            type="submit"
                        >
                            {sendButton}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
