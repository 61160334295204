import { useContext } from "react";
import { LanguageContext } from "../context/language";
import ExpandingButton from "../components/ExpandingButton";
import navData from "../data/navData.json";

const Home = ({ languageData }) => {
    
    const { language } = useContext(LanguageContext);
    const pages = navData.pages.slice(1);
    const title = languageData.home.title;
    const content = languageData.home.content;

    return (
        <div className="w-full h-full grid justify-items-center content-center p-3">
            <div className="w-full w-xs sm:w-[70%] md:w-[94%] h-[25vh] md:h-[20vh] grid grid-rows-6 p-5 mb-3 bg-white/70 dark:bg-blue-950/50 text-blue-800 dark:text-white backdrop-blur-md md:backdrop-blur-none border border-blue-800 dark:border-white/50 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.3)] overflow-hidden  animate-doItAppear">
                <h1 className="row-span-2 text-center font-bold text-lg sm:text-2xl md:text-3xl pb-0 mb-2 lg:mb-5">{title}</h1>
                <div className="row-span-4 flex items-center">
                    <p className="text-center text-sm md:text-lg">{content}</p>
                </div>
            </div>
            <div className="w-full w-xs sm:w-[70%] md:w-[94%] grid grid-rows-4 grid-cols-1 md:grid-rows-2 md:grid-cols-4 md:grid-flow-row gap-4 md:gap-5">
                {pages.map((page, index) => {
                    return (
                        <div
                            key={index}
                            className={`md:row-${index} md:col-span-2`}
                        >
                            <ExpandingButton
                                page={page[language.lang]}
                                link={`/${language.lang}${page.link}`}
                                imageLinks={page.imageLinks}
                                description={languageData[page.link.substring(1)].short_desc}
                                animation={
                                    index % 2 === 0 ? "animate-moveAndGrowL" : "animate-moveAndGrowR"
                                }
                                delay={index}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Home;
