import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HomeSlideShow from "./HomeSlideShow";

const ExpandingSquare = ({ page, link, imageLinks, description, animation }) => {

    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const expandTimer = setTimeout(() => {
            setExpanded(true);
        }, 3500); // Start growing after 3 seconds

        return () => clearTimeout(expandTimer);
    }, []);

    const pageLink = (
        <div className="relative w-full h-[16vh] lg:h-[18vh] xl:h-[20vh]">
            <Link
                className={`absolute h-full backdrop-blur-md md:backdrop-blur-none md:bg-blue-800/70 dark:bg-blue-950/50 border border-white/50 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.3)] overflow-hidden z-10 ${animation}`}
                to={link}
            >
                <div
                    className={`relative w-full h-full flex overflow-hidden ${
                        animation === "animate-moveAndGrowR" && "justify-end"
                    }`}
                >
                    <div className={`flex-none h-full aspect-square z-10 overflow-hidden ${
                        animation === "animate-moveAndGrowR" && "order-last"
                    }`}>
                        <HomeSlideShow imageLinks={imageLinks} alt={page} link={link} />
                    </div>
                    {expanded && <div className="w-full h-full flex flex-col p-2 z-10 overflow-hidden">
                        <h2
                            className={`uppercase text-xl md:text-xl md:text-center shining-box shining-animation text-blue-800 dark:text-blue-950 font-bold mb-1 md:mb-4 p-0 ${
                                animation === "animate-moveAndGrowR"
                                    ? "text-left pl-2"
                                    : "text-right pr-2"
                            }`}
                        >
                            {page}
                        </h2>
                        <p
                            className={`flex-1 flex items-center text-sm lg:text-base md:text-center ${
                                animation === "animate-moveAndGrowR"
                                    ? "text-left"
                                    : "text-right"
                            }`}
                        >
                            {description}
                        </p>
                    </div>}
                </div>
            </Link>
        </div>
    );

    return pageLink;
};

export default ExpandingSquare;
