import { useEffect } from "react";

const SquaresContainer = () => {
    useEffect(() => {
        const generateSquare = () => {
            const container = document.getElementById("squaresContainer");
            const centerX = window.innerWidth / 2; // Center X coordinate of the window
            const centerY = window.innerHeight / 2; // Center Y coordinate of the window
            const squareSize = Math.random() * 150; // Random size from 0 to 150 pixels

            const square = document.createElement("div");
            square.className = "child-square";
            square.classList.add(
                "absolute",
                "p-2",
                "border",
                "rounded-lg",
                "shadow-[0_10px_10px_-3px_rgba(0,0,0,0.6)]",
            );

            const getRandomImage = () => {
                const images = [
                    "api",
                    "css3",
                    "database",
                    "figma",
                    "git",
                    "github",
                    "html",
                    "html5",
                    "internet",
                    "javascript",
                    "mongodb",
                    "nextjs",
                    "nodejs",
                    "postgresql",
                    "prisma",
                    "scrum",
                    "react",
                    "responsive",
                    "typescript"
                ];

                return images[Math.floor(Math.random() * images.length)];
            };

            const image = getRandomImage();
            square.innerHTML += `<img h-fit src="/svg/${image}.svg" alt="${image}" />`;

            // Generate a random shade of blue or orange
            const getRandomColor = () => {
                const blueShades = [
                    "#007bff",
                    "#0069d9",
                    "#0056b3",
                    "#00428f",
                    "#002d5a"
                ];
                const orangeShades = [
                    "#ff7c00",
                    "#ff6a00",
                    "#ff5900",
                    "#ff4700",
                    "#ff3600"
                ];

                const colors = Math.random() < 0.5 ? blueShades : orangeShades;
                return colors[Math.floor(Math.random() * colors.length)];
            };

            const color = getRandomColor();
            square.style.backgroundColor = color;

            square.style.width = `${squareSize}px`;
            square.style.height = `${squareSize}px`;

            if (container) {
                container.appendChild(square);
            }

            // Calculate random direction for each square
            const angle = Math.random() * Math.PI * 2;
            const distance =
                Math.random() * Math.max(window.innerWidth, window.innerHeight); // Distance from the center to the farthest edge

            // Calculate end position for the square
            const endX = centerX + Math.cos(angle) * distance;
            const endY = centerY + Math.sin(angle) * distance;

            // Animate the square
            square.animate(
                [
                    {
                        transform: `translate(${centerX}px, ${centerY}px) scale(0)`,
                        opacity: 0
                    },
                    {
                        transform: `translate(${endX}px, ${endY}px) scale(1)`,
                        opacity: 0.3
                    }
                ],
                {
                    duration: 7000,
                    easing: "linear",
                    fill: "forwards"
                }
            );

            setTimeout(() => {
                if (square.parentNode) {
                    square.parentNode.removeChild(square);
                }
            }, 8000); // 8 seconds delay before removing the child
        };

        const intervalId = setInterval(generateSquare, 30); // Generate squares every 30ms

        return () => clearInterval(intervalId); // Clear the interval on component unmount
    }, []);

    return (
        <div
            id="squaresContainer"
            className="absolute inset-0 z-0 bg-gradient-to-b from-blue-300 via-blue-900 to-blue-500 dark:bg-gradient-to-b dark:from-blue-900 dark:via-black dark:to-blue-900"
        ></div>
    );
};

export default SquaresContainer;
