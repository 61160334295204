import { useState, useEffect, useContext } from "react";
import { LanguageContext } from "../context/language";

const HomeSlideShow = ({ imageLinks, alt, link }) => {
    const { language } = useContext(LanguageContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSingleImage, setIsSingleImage] = useState(true);
    const techLink = link === `/${language.lang}/tech`;

    useEffect(() => {
        if (imageLinks.length > 1) {
            setIsSingleImage(false);
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imageLinks.length);
            }, 2000); // Change slide every 2 seconds

            return () => clearInterval(interval); // Clean up interval on component unmount
        }
    }, [imageLinks]);

    function getTechName(imageLink) {
        const techName = imageLink.split("/").pop().split(".")[0];
        return techName;
    }

    return (
        <div className="relative h-full aspect-square">
            {isSingleImage ? (
                <div className="h-full aspect-square p-2">
                    <img
                        src={imageLinks[0]}
                        alt={alt}
                        className="h-full border border-white/50"
                    />
                </div>
            ) : (
                <div
                    className="h-full p-2"
                >
                    <div className={`${
                        techLink && "grid grid-rows-4 justify-items-center bg-blue-950/30"
                    } h-full aspect-square border border-white/50`}>
                        <img
                            src={imageLinks[currentIndex]}
                            alt={alt}
                            className={`${
                                techLink
                                    ? "row-start-1 row-span-3 h-[70%] place-self-center"
                                    : "h-full"
                            } `}
                        />
                        {techLink && (
                            <div className="row-start-4 w-full text-sm text-center">
                                {getTechName(imageLinks[currentIndex])}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomeSlideShow;
