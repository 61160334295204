import { useState } from "react";
import { Link } from "react-router-dom";

function ProjectCard({ project }) {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="w-full grid grid-cols-1 justify-items-center pt-8 mt-8">
            <div className="w-full md:w-[70%]">
                <div className="relative bg-white text-sky-700">
                    <h3 className="p-1">{project.projectName}</h3>
                    <div
                        className={`absolute right-1 top-1 cursor-pointer transition duration-500 ease-in-out ${
                            isVisible ? "rotate-180" : "rotate-0"
                        }`}
                        onClick={toggleVisibility}
                    >
                        ▼
                    </div>
                </div>
                <div
                    className={` border-x-2 transition-all duration-700 ease-in-out overflow-hidden ${
                        isVisible ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
                    }`}
                >
                    <p className="p-2 pb-1 text-justify line-clamp-3">{project.projectOverview}</p>
                </div>
                <Link to={`/project/${project.projectName}`}>
                    <img
                        className="w-full bg-white/30 p-2 border-2 border-solid border-white"
                        src={project.projectPreview}
                        alt={project.projectName}
                    />
                </Link>
            </div>
        </div>
    );
}

export default ProjectCard;
