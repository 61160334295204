const About = ({ languageData }) => {
    return (
        <div className="h-full hyphens-auto text-justify scroll-smooth scroll-mt-0 snap-start overflow-hidden overflow-y-auto">
            <h1 className="bg-blue-800/70 p-3 mb-5 shadow-lg border-b">{languageData.about.title}</h1>
            <div className="flex justify-center">
                <img src="../images/me/Pio.png" alt="About me" className="w-[30%] md:w-[25%] border border-white/50 shadow-[0_10px_15px_-3px_rgba(0,0,0,0.3)]" />
            </div>
            {
                languageData.about.content.map((obj, index) => (
                    <div key={index} className="mt-4 p-5">
                        <h2 className="pb-1 text-left text-blue-900 dark:text-blue-400">{Object.keys(obj)}</h2>
                        <p>{obj[Object.keys(obj)]}</p>
                    </div>
                ))
            }
        </div>
    );
};

export default About;