import { useState, useContext } from "react";
import { LanguageContext } from "../context/language";

const CustomSelect = ({ toggleMenu }) => {
    const { language, setLanguage } = useContext(LanguageContext);
    const [isOpen, setIsOpen] = useState(false);
    const isTouchDevice = "ontouchstart" in window || navigator.maxTouchPoints > 0;

    const options = ["it", "de", "en"];

    const handleSelect = (option) => {
        setLanguage({ lang: option });
        document.cookie = `preferredLanguage=${option}; path=/; max-age=31536000`;
        localStorage.setItem("preferredLanguage", option);
        setIsOpen(false);
        // Get the current path and replace the language segment
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split("/");
        pathParts[1] = option; // Update language segment

        // Create the new path with the updated language
        const newPath = pathParts.join("/");

        // Redirect to the new path
        window.location.href = newPath;

        // If it's a touch device, close the menu
        if (isTouchDevice) {
            toggleMenu();
        }
    };

    return (
        <div className="relative">
            <div
                className="cursor-pointer p-2 inline-flex hover:text-white dark:hover:text-blue-400"
                onClick={() => setIsOpen(!isOpen)}
            >
                {language.lang.toUpperCase()}
                <div
                    className={`ml-1 transition duration-500 ease-in-out ${
                        isOpen ? "rotate-180" : "rotate-0"
                    }`}
                >
                    ▼
                </div>
            </div>
            {isOpen && (
                <div className="absolute w-full mt-1 border rounded bg-white/70 dark:bg-black/70 z-10">
                    {options
                        .filter((option) => option !== language.lang)
                        .map((option) => (
                            <div
                                key={option}
                                className="p-2 cursor-pointer text-center hover:bg-blue-700 hover:text-white dark:hover:bg-white dark:hover:text-blue-950"
                                onClick={() => handleSelect(option)}
                            >
                                {option.toUpperCase()}
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default CustomSelect;
