function TechList({ data }) {

    const title = Object.keys(data);

    return (
        <div className="mt-4">
            <h2 className="bg-blue-700/50 my-4 p-1 shadow-md">{title}</h2>
            <ul className="list-disc pl-5">
                {
                    data[title].map((obj, index) => (
                        <li key={index} className="mt-1">
                            <span className="font-medium text-blue-900 dark:text-blue-400">{Object.keys(obj)}: </span>
                            {obj[Object.keys(obj)]}
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}

export default TechList;