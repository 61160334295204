import React, { createContext, useState, useEffect } from "react";

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState({ lang: 'en' }); // Default language

    useEffect(() => {
        const storedLanguage = localStorage.getItem('preferredLanguage');
        if (storedLanguage) {
            setLanguage({ lang: storedLanguage });
        }
    }, []);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
}

export { LanguageContext, LanguageProvider };